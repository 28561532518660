import React from "react";

import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

import { Box } from "~components/Box";

import { AnimLayerBalanceCardItem } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import { AnimLayerPhoneBgBalanceCards } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgBalanceCards";

import type { SvgVerificationPhoneBgProps } from "../__layers__/SvgVerificationPhoneBg";
import type { AnimatedHeroIllustration } from "../types";
import type { AnimLayerBalanceCardListProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardList";
import type { AnimLayerPhoneBgBalanceCardsProps } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgBalanceCards";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimHeroVerificationProps
  extends AnimatedHeroIllustration,
    SvgVerificationPhoneBgProps {
  animLayerBalance?: Array<StoryblokBlok & AnimLayerBalanceCardListProps>;
  animLayerPhoneBg: Array<StoryblokBlok & AnimLayerPhoneBgBalanceCardsProps>;
}

export function AnimHeroVerification({
  animLayerBalance: animLayerBalanceBlokArray,
  animLayerPhoneBg: animLayerPhoneBgArray,
  ...rest
}: AnimHeroVerificationProps) {
  const [animLayerBalance] = animLayerBalanceBlokArray || [];
  const [animLayerPhoneBg] = animLayerPhoneBgArray || [];

  return (
    <Box {...rest} position="relative" width="100%" height="100%">
      {animLayerPhoneBg && (
        <AnimLayerPhoneBgBalanceCards
          inset="0"
          marginX="auto"
          maxWidth="gridSpan3"
          paddingTop="spacing12"
          position="absolute"
          __top={vars.spacing.spacing16}
          {...animLayerPhoneBg}
        />
      )}
      {animLayerBalance && (
        <AnimLayerBalanceCardItem
          inset="0"
          marginX="auto"
          position="absolute"
          __maxWidth={calc.subtract(
            vars.gridSpan.gridSpan3,
            vars.spacing.spacing2
          )}
          __top={vars.spacing.spacing12}
          {...animLayerBalance}
        />
      )}
    </Box>
  );
}
