/* eslint-disable sonarjs/no-duplicate-string */
import React from "react";

import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

import { Box } from "~components/Box";

import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimLayerPhoneBgCalendar } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgCalendar";

import type { AnimatedHeroIllustration } from "../types";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { AnimLayerPhoneBgCalendarProps } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgCalendar";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimHeroVrpProps extends AnimatedHeroIllustration {
  animLayerInfoDialog: Array<StoryblokBlok & AnimLayerInfoDialogProps>;
  animLayerPhoneBg?: Array<StoryblokBlok & AnimLayerPhoneBgCalendarProps>;
}

export function AnimHeroVrp({
  animLayerInfoDialog: animLayerInfoDialogBlokArray,
  animLayerPhoneBg: animLayerPhoneBgBlokArray,
  ...rest
}: AnimHeroVrpProps) {
  const [animLayerInfoDialog] = animLayerInfoDialogBlokArray || [];
  const [animLayerPhoneBg] = animLayerPhoneBgBlokArray || [];

  return (
    <Box {...rest} position="relative" width="100%" height="100%">
      {animLayerPhoneBg && (
        <AnimLayerPhoneBgCalendar
          inset="0"
          marginX="auto"
          maxWidth="gridSpan3"
          paddingTop="spacing20"
          position="absolute"
          __top={vars.spacing.spacing20}
          {...animLayerPhoneBg}
        />
      )}

      {animLayerInfoDialog && (
        <AnimLayerInfoDialog
          inset="0"
          marginX="auto"
          position="absolute"
          __maxWidth={calc.subtract(
            vars.gridSpan.gridSpan3,
            vars.spacing.spacing2
          )}
          __top={vars.spacing.spacing10}
          {...animLayerInfoDialog}
        />
      )}
    </Box>
  );
}
