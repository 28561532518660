import React from "react";

import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

import { Box } from "~components/Box";

import { AnimLayerBalanceCardItem } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import { AnimLayerPhoneBgTransactionList } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgTransactionList";

import type { AnimatedHeroIllustration } from "../types";
import type { AnimLayerBalanceCardListProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardList";
import type { AnimLayerPhoneBgTransactionListProps } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgTransactionList";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimHeroPayoutsProps extends AnimatedHeroIllustration {
  animLayerBalanceCardItem: Array<
    StoryblokBlok & AnimLayerBalanceCardListProps
  >;
  animLayerPhoneBg: Array<StoryblokBlok & AnimLayerPhoneBgTransactionListProps>;
}

export function AnimHeroPayouts({
  animLayerBalanceCardItem: animLayerBalanceCardItemBlokArray,
  animLayerPhoneBg: animLayerPhoneBgArray,

  ...rest
}: AnimHeroPayoutsProps) {
  const [animLayerBalanceCardItem] = animLayerBalanceCardItemBlokArray || [];
  const [animLayerPhoneBg] = animLayerPhoneBgArray || [];

  return (
    <Box {...rest} position="relative" width="100%" height="100%">
      {animLayerPhoneBg && (
        <AnimLayerPhoneBgTransactionList
          inset="0"
          marginX="auto"
          maxWidth="gridSpan3"
          paddingTop="spacing14"
          position="absolute"
          __top={vars.spacing.spacing16}
          {...animLayerPhoneBg}
        />
      )}
      {animLayerBalanceCardItem && (
        <AnimLayerBalanceCardItem
          inset="0"
          marginX="auto"
          position="absolute"
          __maxWidth={calc.subtract(
            vars.gridSpan.gridSpan3,
            vars.spacing.spacing2
          )}
          __top={vars.spacing.spacing12}
          {...animLayerBalanceCardItem}
        />
      )}
    </Box>
  );
}
