import { timeline } from "motion";

// import { vars } from "~styles/themes/theme.css";
import { getMotionSelector } from "~utils/motion_one/motion_attribute_selectors";

import {
  COLOR_DOTTED_PATH,
  COLOR_STROKE,
  DURATION_NETWORK_FLOW,
  STROKE_WIDTH_DOTTED_PATH,
} from "./_USER_NETWORK_GRID.config";
import { getDashedPathOffset } from "./_getDashedPathOffset";

import type { MotionTimelineDefinition } from "~animations/products_addons/types";
import type { MotionGroupsDict } from "~hooks/useDirectionAwareTransitions/types";

interface AnimateNetworkFlowArgs {
  motionGroups: MotionGroupsDict;
}

export const animateNetworkFlow = ({
  motionGroups,
}: AnimateNetworkFlowArgs) => {
  if (!motionGroups) return;
  const sequence: MotionTimelineDefinition = [];

  Object.values(motionGroups)?.forEach((groupElements) => {
    groupElements?.forEach((group) => {
      /**
       * Get elements within this group
       */

      const logo = group.querySelector(getMotionSelector("logo"));
      const dottedPaths = group.querySelectorAll<SVGPathElement>(
        getMotionSelector("dottedPaths")
      );

      /**
       * Create an empty timeline sequence.
       */

      /**
       * If there is a logo, add it to the sequence.
       * Animation should "pulse" briefly at the start of the timeline.
       */
      if (logo) {
        sequence.push([
          logo,
          {
            transform: ["scale(1)", "scale(1.05)", "scale(1)"],
            color: [COLOR_STROKE, COLOR_DOTTED_PATH, COLOR_STROKE],
          },
          { at: "<", duration: 0.05, easing: "ease-in-out" },
        ]);
      }

      /**
       * Add the "network flow" animation to each dotted path,
       * ensuring that they all start at the start of the sequence.
       */
      dottedPaths.forEach((path) => {
        const dottedPathLength = path.getTotalLength();
        const dashOffset = getDashedPathOffset(dottedPathLength, 1);

        sequence.push([
          path,
          {
            strokeDashoffset: [dottedPathLength, dottedPathLength * 1.5],
            strokeDasharray: [dashOffset, dashOffset],
            opacity: [1, 0],
            strokeWidth: [STROKE_WIDTH_DOTTED_PATH, 0],
          },
          {
            at: "<",
            endDelay: DURATION_NETWORK_FLOW,
          },
        ]);
      });
    });
  });

  timeline(sequence, {
    duration: DURATION_NETWORK_FLOW,
    repeat: Infinity,

    // delay: stagger(0.1, {}),
  });
};
