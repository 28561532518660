import React from "react";

import { getSprinkles } from "~styles/getSprinkles.css";

import { GRID_PATH_PROPS } from "./_USER_NETWORK_GRID.config";

export function SvgFragmentPaths1() {
  return (
    <>
      <path
        data-name="pathVerticalBottomRight"
        d="M2511.632 357.1V1088"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathHorizBottom"
        d="M1814.987 834.767h696.658"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathHorizBottomRight"
        d="M1814.987 1088H3840"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathHorizTopLeft"
        d="M0 113.715h1814.986"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathVertCenter"
        d="M1814.987.125V1088"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathCurvedLeft"
        d="M1481.747.125v710.713c0 151.308-122.66 273.968-273.968 273.968H0"
        {...GRID_PATH_PROPS}
        className={getSprinkles({
          display: { mobile: "none", tablet: "block" },
        })}
      />

      {/* Top right visible on mobile */}

      <path
        data-name="pathCurvedRight"
        d="M2808.276 0v258.204c0 54.618-58.524 98.895-130.716 98.895h-518.066"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathCurvedRight"
        d="M3840 716.21H2517.048c-197.474 0-357.554-160.076-357.554-357.548S2319.574 1.115 2517.411-.46H3840"
        {...GRID_PATH_PROPS}
      />
    </>
  );
}
