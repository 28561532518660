import React from "react";

import { animate, inView, stagger } from "motion";

import { getAllMotionGroups } from "~hooks/useDirectionAwareTransitions/support/_getAllMotionGroups";

import { getElemsByMotionAttr } from "~utils/motion_one/motion_attribute_selectors";

import {
  CIRCLE_DRAW_STAGGER,
  ICON_FADE_DURATION,
} from "./_USER_NETWORK_GRID.config";
import { animateNetworkFlow } from "./_animateNetworkFlow";
import { animatePaths } from "./_animatePaths";

export const useNetworkGridAnimation = () => {
  const refOuter = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!refOuter.current || typeof window === "undefined") return;

    /**
     * Get all of the elements that we want to animate, which are
     * those marked with the `data-motion` or `data-motion-group` attributes.
     */
    const paths = getElemsByMotionAttr<SVGPathElement>(refOuter, "path");
    const logos = getElemsByMotionAttr<SVGPathElement>(refOuter, "logo");
    const motionGroups = getAllMotionGroups(refOuter);

    inView(refOuter.current, () => {
      /**
       * Animate in paths
       */
      animatePaths(paths);

      /**
       * Animate in logos/icons
       */
      animate(
        logos,
        {
          opacity: [0, 1],
          transform: ["scale(0.5)", "scale(1)"],
        },
        {
          duration: ICON_FADE_DURATION,
          easing: "ease-in",
          delay: stagger(CIRCLE_DRAW_STAGGER, {
            start: CIRCLE_DRAW_STAGGER,
          }),
        }
      )
        /**
         * Once all of the logos/icons have finished animating,
         * begin the looping "network flow" animation.
         */
        .finished.then(() => {
          if (motionGroups) {
            animateNetworkFlow({ motionGroups });
          }
        });
    });
  });

  return refOuter;
};
