import { animate, stagger } from "motion";

import {
  LINE_DRAW_DURATION,
  LINE_DRAW_STAGGER,
} from "./_USER_NETWORK_GRID.config";

export const animatePaths = (paths: never[] | NodeListOf<SVGPathElement>) => {
  return Array.from(paths).forEach((path) => {
    if (!path) return;

    animate(
      path,
      {
        strokeDashoffset: [path.getTotalLength(), 0],
        strokeDasharray: [path.getTotalLength(), path.getTotalLength()],
      },
      {
        duration: LINE_DRAW_DURATION,
        easing: "ease-in",
        delay: stagger(LINE_DRAW_STAGGER),
      }
    );
  });
};
