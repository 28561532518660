import * as React from "react";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";

import { SvgFragmentIcons2 } from "./_SvgFragmentIcons2";
import { SvgFragmentPaths2 } from "./_SvgFragmentPaths2";
import { GRID_WRAPPER_PROPS } from "./_USER_NETWORK_GRID.config";
import { useNetworkGridAnimation } from "./_useNetworkGridAnimations";

import type { BoxProps } from "~components/Box";

export type AnimationUserNetworkGrid1Props = BoxProps;

export function AnimationUserNetworkGrid2({
  ...rest
}: AnimationUserNetworkGrid1Props) {
  const refOuter = useNetworkGridAnimation();

  return (
    <Box {...rest} ref={refOuter} {...GRID_WRAPPER_PROPS}>
      <svg
        className={getSprinkles({
          height: "90vh",
          marginX: "auto",
          flexShrink: "0",
        })}
        viewBox="0 0 3840 1088"
        xmlns="http://www.w3.org/2000/svg"
      >
        <SvgFragmentPaths2 />
        <SvgFragmentIcons2 />
      </svg>
    </Box>
  );
}
