import { useEffect, useRef } from "react";

import { animate, inView, stagger } from "motion";

import { vars } from "~styles/themes/theme.css";

import { varsClipPathInset } from "~styles/vars/varsClipPathInset.css";

import {
  ANIMATED,
  getMotionSelector,
} from "~utils/motion_one/motion_attribute_selectors";

interface useAnimationsHeroArgs {
  clipPath?: keyof typeof varsClipPathInset;
}

export const useAnimationsHero = ({ clipPath }: useAnimationsHeroArgs) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const clipPathForAnim = varsClipPathInset[clipPath || "default"];

  useEffect(() => {
    if (!refContainer.current || typeof window === "undefined") return;

    const mask = refContainer.current.querySelector(
      getMotionSelector(ANIMATED.mask)
    );
    const animatedPrimary = refContainer.current.querySelectorAll(
      getMotionSelector(ANIMATED.primary)
    );
    // const secondaryElements = refContainer.current?.querySelectorAll(
    //   getMotionSelector(ANIMATED.secondary)
    // );

    inView(refContainer.current, () => {
      if (mask && clipPath) {
        animate(
          mask,
          {
            clipPath: [varsClipPathInset.hidden, clipPathForAnim],
          },
          {
            duration: 0.5,
          }
        );
      }

      if (animatedPrimary?.length > 0) {
        animate(
          animatedPrimary,
          {
            opacity: [0, 1],
            y: [vars.spacing.spacing5, 0],
          },
          {
            duration: 0.5,
            delay: stagger(0.2, { start: 0.2 }),
          }
        );
      }

      // if (secondaryElements?.length > 0) {
      //   animate(
      //     secondaryElements,
      //     {
      //       opacity: [0, 1],
      //       transform: [
      //         getDirectionAwareTranslateY({
      //           direction: "in",
      //           current: 1,
      //           previous: 0,
      //           scale: "sm",
      //         }),
      //         "translateY(0px)",
      //       ],
      //     },
      //     { duration: 0.5, delay: stagger(0.05) }
      //   );
      // }
    });
  });

  return refContainer;
};
