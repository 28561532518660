import React from "react";

import { GRID_PATH_PROPS } from "./_USER_NETWORK_GRID.config";

export function SvgFragmentPaths2() {
  return (
    <>
      <path
        data-name="pathCurvedRight"
        d="M3840 696H2669.487c-192.198 0-348-155.8-348-348s155.802-348 348-348H3840"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathVertLeft"
        d="M1128.512 0v1088"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathVertCenter"
        d="M2138.479 0v1088"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathHorizCenter"
        d="M2138.479 348h183.008"
        {...GRID_PATH_PROPS}
      />
      <path
        data-name="pathCurvedLeft"
        d="M0 117.036h1639.758c151.256 0 273.874 122.617 273.874 273.873V1088"
        {...GRID_PATH_PROPS}
      />
    </>
  );
}
