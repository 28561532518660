export const getDashedPathOffset = (pathLength: number, numDots: number) => {
  return Array.from({ length: numDots })
    .reduce((accumulator: Array<number>) => {
      const emptySpace = pathLength / numDots - 1;

      accumulator.push(0.5);
      accumulator.push(emptySpace);

      return accumulator;
    }, [])
    .join(" ");
};
