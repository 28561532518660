/**
 * ------------------------------------------------------------------------------
 * Config for appearance of the grid
 * ------------------------------------------------------------------------------
 */
import { vars } from "~styles/themes/theme.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import type { BoxProps } from "~components/Box";

export const STROKE_WIDTH_GRID_PATH = 1;
export const STROKE_WIDTH_DOTTED_PATH = 8;

export const COLOR_BG_ICON = vars.color.background.default;
export const COLOR_STROKE = vars.color.networkGridIllustration.staticPathColor;
export const COLOR_DOTTED_PATH =
  vars.color.networkGridIllustration.animatedPathColor;

/**
 * ------------------------------------------------------------------------------
 * Wrapper for the outer grid element
 * ------------------------------------------------------------------------------
 */

export const GRID_WRAPPER_PROPS: BoxProps = {
  alignItems: "start",
  display: "flex",
  inset: "0",
  justifyContent: "center",
  maxWidth: "100vw",
  minHeight: "100vh",
  overflowX: "hidden",
  position: "absolute",
} as const;

/**
 * ------------------------------------------------------------------------------
 * Common props for SVG elements
 * ------------------------------------------------------------------------------
 */

export const GRID_PATH_PROPS = {
  stroke: COLOR_STROKE,
  fill: "none",
  strokeDashoffset: "-1",
  strokeDasharray: "0 0",
  strokeWidth: STROKE_WIDTH_GRID_PATH,
  "data-motion": ANIMATED.path,
} as const;

export const GRID_ANIMATED_PATH_PROPS = {
  stroke: COLOR_DOTTED_PATH,
  fill: "none",
  strokeLinecap: "round",
  strokeWidth: 0,
  "data-motion": ANIMATED.dottedPaths,
} as const;

export const ICON_SVG_GROUP_PROPS = {
  "data-motion": ANIMATED.logo,
  style: {
    transformOrigin: "50% 50%",
    transformBox: "fill-box",
  },
  color: COLOR_STROKE,
  // color: "red",
} as const;

export const ICON_CIRCLE_SVG_PROPS = {
  fill: COLOR_BG_ICON,
  stroke: "currentColor",
  strokeMiterlimit: 10,
  strokeWidth: STROKE_WIDTH_GRID_PATH,
} as const;

export const ICON_SVG_PROPS = {
  fill: "currentColor",
} as const;

/**
 * ------------------------------------------------------------------------------
 * Animation timing
 * ------------------------------------------------------------------------------
 */

export const DURATION_SCALAR = 1;
export const LINE_DRAW_DURATION = DURATION_SCALAR * 1;
export const LINE_DRAW_STAGGER = DURATION_SCALAR * 3;
export const DURATION_NETWORK_FLOW = DURATION_SCALAR * 4;
export const ICON_FADE_DURATION = DURATION_SCALAR * 0.5;
export const CIRCLE_DRAW_STAGGER = DURATION_SCALAR * 0.2;
