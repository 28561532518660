import React from "react";

import {
  // GRID_ANIMATED_PATH_PROPS,
  ICON_CIRCLE_SVG_PROPS,
  ICON_SVG_GROUP_PROPS,
  ICON_SVG_PROPS,
} from "./_USER_NETWORK_GRID.config";

export function SvgFragmentIcons2() {
  return (
    <>
      <g data-motion-group={1}>
        <g data-name="logoSantander" {...ICON_SVG_GROUP_PROPS}>
          <circle
            cx={1128.512}
            cy={117.036}
            r={29.01}
            {...ICON_CIRCLE_SVG_PROPS}
          />
          <path
            d="M1146.315 124.314c0 5.336-7.968 9.644-17.804 9.644-9.834 0-17.802-4.308-17.802-9.644 0-3.838 4.1-7.137 10.073-8.697a7.572 7.572 0 0 0 1.022 4.192l5.57 9.743c.428.735.71 1.518.875 2.326l.238-.404a7.75 7.75 0 0 0 0-7.73l-4.48-7.748c-1.352-2.4-1.352-5.337.025-7.706l.239-.403c.164.807.446 1.584.875 2.325l2.607 4.5 4.076 7.068c.428.734.71 1.517.881 2.32l.232-.405a7.736 7.736 0 0 0 0-7.723l-4.455-7.73a7.75 7.75 0 0 1 0-7.73l.24-.398c.164.802.445 1.585.874 2.32l5.57 9.645a7.914 7.914 0 0 1 1.022 3.538c5.973 1.56 10.098 4.859 10.122 8.697Z"
            {...ICON_SVG_PROPS}
          />
        </g>
        {/* <path
          data-name="dottedPathTopLeft"
          d="M1099.502 117.036H869.465h230.037Z"
          {...GRID_ANIMATED_PATH_PROPS}
        />
        <path
          data-name="dottedPathTopRight"
          d="M1157.522 117.036h230.037-230.037Z"
          {...GRID_ANIMATED_PATH_PROPS}
        /> */}
      </g>

      <g data-name="iconData" {...ICON_SVG_GROUP_PROPS}>
        <circle cx={1644.5} cy={117.036} r={29.01} {...ICON_CIRCLE_SVG_PROPS} />
        <path
          d="M1641.177 132.811h1.661v-4.15h-1.66v4.15Zm4.983 0h1.66v-11.623h-1.66v11.623Zm-1.661-22.417a3.32 3.32 0 0 0-3.322 3.32v11.625h1.66v-11.625a1.66 1.66 0 0 1 1.662-1.66 1.662 1.662 0 0 1 1.66 1.66v4.152h1.661v-4.152a3.321 3.321 0 0 0-3.322-3.32Zm11.624 17.436h1.661v-4.982h-1.66v4.982Zm-11.624-27.466a13.229 13.229 0 0 0-9.076 3.578l1.138 1.212a11.627 11.627 0 0 1 12.567-2.17 11.61 11.61 0 0 1 5.09 4.288 11.616 11.616 0 0 1 1.905 6.376v5.88h1.661v-5.88a13.286 13.286 0 0 0-13.285-13.284Zm-13.284 13.284v14.182h1.66v-14.182a11.52 11.52 0 0 1 .913-4.525l-1.528-.647a13.113 13.113 0 0 0-1.045 5.172Zm18.98-3.42a6.586 6.586 0 0 1 .946 3.42v16.672h1.66v-16.672a8.307 8.307 0 0 0-1.186-4.284l-1.42.863Zm-14 3.42v2.558h1.662v-2.558a6.642 6.642 0 0 1 10.212-5.604l.889-1.403a8.31 8.31 0 0 0-8.455-.275 8.302 8.302 0 0 0-4.307 7.282Zm0 16.672h1.662v-10.793h-1.661v10.793Z"
          {...ICON_SVG_PROPS}
        />
      </g>

      <g data-name="iconUser" {...ICON_SVG_GROUP_PROPS}>
        <circle
          cx={2416.625}
          cy={114.596}
          r={29.01}
          {...ICON_CIRCLE_SVG_PROPS}
        />
        <path
          d="M2416.61 114.162a5.915 5.915 0 0 0 3.269-.972 5.84 5.84 0 0 0 2.176-2.601 5.792 5.792 0 0 0 .338-3.353 5.815 5.815 0 0 0-1.608-2.975 5.925 5.925 0 0 0-6.406-1.263 5.849 5.849 0 0 0-2.64 2.137 5.745 5.745 0 0 0 .725 7.322 5.913 5.913 0 0 0 4.146 1.705Zm0-8.918a3.166 3.166 0 0 1 2.942 1.926 3.098 3.098 0 0 1-.688 3.415 3.175 3.175 0 0 1-1.62.857c-.62.12-1.258.057-1.839-.181a3.162 3.162 0 0 1-1.421-1.158 3.104 3.104 0 0 1-.528-1.743c0-.827.332-1.619.924-2.204a3.175 3.175 0 0 1 2.23-.912Zm7.294 9.8v2.674c.58.004 1.136.232 1.547.634.4.403.64.95.64 1.519v1.859c0 .59-.24 1.159-.664 1.578a2.282 2.282 0 0 1-1.595.654h-14.439a2.28 2.28 0 0 1-1.6-.654 2.226 2.226 0 0 1-.661-1.578v-1.86a2.163 2.163 0 0 1 .676-1.542 2.22 2.22 0 0 1 1.586-.61l-.082-2.674a4.912 4.912 0 0 0-3.457 1.414c-.453.448-.813.98-1.059 1.566s-.372 1.213-.372 1.847v1.859a4.892 4.892 0 0 0 1.459 3.465 5.022 5.022 0 0 0 3.51 1.441h14.439a5.006 5.006 0 0 0 3.505-1.44 4.881 4.881 0 0 0 1.463-3.466v-1.86c0-1.28-.52-2.507-1.439-3.412a4.887 4.887 0 0 0-3.457-1.414Z"
          {...ICON_SVG_PROPS}
        />
      </g>

      <g data-motion-group={1}>
        <g data-name="logoRevolut" {...ICON_SVG_GROUP_PROPS}>
          <circle
            cx={2138.479}
            cy={539.006}
            r={29.01}
            {...ICON_CIRCLE_SVG_PROPS}
          />
          <g fill="#e5e4dc">
            <path
              d="M2138.937 530.419h-1.45c-.146.61-.182 1.174-.279 1.783l-1.04 6.335h1.79c2.296 0 3.988-1.877 3.988-4.787 0-2.017-1.172-3.331-3.01-3.331Z"
              {...ICON_SVG_PROPS}
            />
            <path
              d="M2147.712 541.305c5.5-4.082 4.509-8.727 4.509-8.727-.423-5.585-5.162-9.338-11.967-9.338 0 0-8.304.328-9.803.656-1.789.376-3.01 2.018-2.865 3.802 0 1.22-.048 2.533-.326 3.895l-2.393 15.53c-.194.893-.653 3.708 1.124 5.82.7.845 2.019 1.83 4.315 1.83 1.04 0 3.856-.142 6.153-1.174a3.859 3.859 0 0 0 1.499-1.22c1.22 1.456 3.046 2.488 5.729 2.488 2.345 0 4.642-.61 6.709-1.643 0 0 2.018-1.455 1.172-3.566-1.837-2.674-3.856-8.353-3.856-8.353Zm-.133 9.197c-1.22.376-2.635.658-3.892.61-1.317 0-3.058-.328-4.182-3.52l-1.874-5.49h-1.873l-.847 5.21a7.472 7.472 0 0 0-.193 1.783v.14c0 .33.048.658.193.986-1.462.657-3.384.844-4.557.844-.979 0-1.873-.328-1.97-2.017 0-.469.049-.892.145-1.361l2.394-15.532c.18-1.173.326-2.393.326-3.613v-1.22c.798-.187 3.614-.516 6.14-.516h2.768c1.693-.047 3.337.329 4.884.985a9.57 9.57 0 0 1 1.172.703c.327.282.665.61.992.893a8.25 8.25 0 0 1 .979 1.69c.193.656.326 1.313.326 2.017v.61c-.23 2.863-2.055 5.209-4.871 6.71l2.526 6.71c.713 1.83 1.172 2.534 1.97 3.144-.181.141-.375.187-.556.234Z"
              {...ICON_SVG_PROPS}
            />
          </g>
        </g>
        {/* <path
          data-name="dottedPathRightTop"
          d="M2138.479 279.959v230.037-230.037Z"
          {...GRID_ANIMATED_PATH_PROPS}
        />
        <path
          data-name="dottedPathRightBottom"
          d="M2138.479 568.016v230.037-230.037Z"
          {...GRID_ANIMATED_PATH_PROPS}
        /> */}
      </g>

      <g data-motion-group={2}>
        <g data-name="logoHsbc" {...ICON_SVG_GROUP_PROPS}>
          <circle
            cx={1128.512}
            cy={617.798}
            r={29.01}
            {...ICON_CIRCLE_SVG_PROPS}
          />
          <g fill="#251884">
            <path
              d="m1149.37 618.023-10.428-10.43v20.859l10.429-10.43Z"
              {...ICON_SVG_PROPS}
            />
            <path
              d="m1128.512 618.023 10.43-10.43h-20.859l10.43 10.43ZM1107.653 618.023l10.43 10.429v-20.86l-10.43 10.43Z"
              {...ICON_SVG_PROPS}
            />
            <path
              d="m1128.512 618.023-10.429 10.429h20.859l-10.43-10.43Z"
              {...ICON_SVG_PROPS}
            />
          </g>
        </g>

        {/* <path
          data-name="dottedPathLeftTop"
          d="M1128.512 358.75v230.038V358.75Z"
          {...GRID_ANIMATED_PATH_PROPS}
        />
        <path
          data-name="dottedPathLeftBottom"
          d="M1128.512 646.808v230.037-230.037Z"
          {...GRID_ANIMATED_PATH_PROPS}
        /> */}
      </g>
    </>
  );
}
